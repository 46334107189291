import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { ProductSpecificationSectionProps } from './models';

import './ProductSpecificationSection.scss';

const ProductSpecificationSection: FC<ProductSpecificationSectionProps> = ({
  productSpecificationSection: {
    topText,
    backgroundImageAltText,
    centralImageAltText,
    backgroundImage,
    centralImage,
    centralImageText,
    smallTopImageAltText,
    smallTopImage,
    smallTopImageText,
    bigPercentFeature,
    smallPercentFeature,
    featureList,
    footnotes,
  },
}) => (
  <div className="product-specification-section">
    {backgroundImage ? (
      <Image
        imageData={backgroundImage}
        alt={backgroundImageAltText}
        className="product-specification-section__background-image-wrapper"
      />
    ) : null}

    {topText ? (
      <div className="product-specification-section__top-text-wrapper">
        {topText?.header ? <h2>{topText.header}</h2> : null}
        {topText?.content ? <DangerouslySetInnerHtml html={topText.content} /> : null}
      </div>
    ) : null}

    {smallTopImage ? (
      <div className="product-specification-section__top-image-wrapper">
        <Image
          imageData={smallTopImage}
          alt={smallTopImageAltText}
          className="product-specification-section__top-image"
        />
        <p>{smallTopImageText}</p>
      </div>
    ) : null}

    {centralImage ? (
      <div className="product-specification-section__central-image-wrapper">
        <Image imageData={centralImage} alt={centralImageAltText} />
        <p>{centralImageText}</p>
      </div>
    ) : null}

    {featureList ? (
      <div className="product-specification-section__feature-list-wrapper">
        <ul>
          {featureList.map(({ content: feature }) => (
            <li key={feature}>
              <DangerouslySetInnerHtml html={feature} />
            </li>
          ))}
        </ul>
      </div>
    ) : null}

    {bigPercentFeature || smallPercentFeature ? (
      <div className="product-specification-section__percent-feature-wrapper">
        {bigPercentFeature ? (
          <div className="product-specification-section__big-percent-wrapper">
            <span className="product-specification-section__big-percent">
              {bigPercentFeature?.percentValue}
            </span>
            <DangerouslySetInnerHtml
              html={bigPercentFeature?.description}
              className="product-specification-section__big-percent-description"
            />
          </div>
        ) : null}

        {smallPercentFeature ? (
          <div>
            <span className="product-specification-section__small-percent">
              {smallPercentFeature?.percentValue}
            </span>
            <DangerouslySetInnerHtml
              html={smallPercentFeature?.description}
              className="product-specification-section__small-percent-description"
            />
          </div>
        ) : null}
      </div>
    ) : null}
    {footnotes ? (
      <div className="product-specification-section__footnotes-wrapper">
        <DangerouslySetInnerHtml html={footnotes} />
      </div>
    ) : null}
  </div>
);

export default ProductSpecificationSection;
