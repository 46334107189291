import React, { FC } from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import SvgImageIcon from 'common/SvgImageIcon';

import { WomanStoryCardProps } from './models';

import './WomanStoryCard.scss';

const WomanStoryCard: FC<WomanStoryCardProps> = ({
  womanStoryCard: { title, womanName, image, story, cardColor, icon },
}) => (
  <div
    className={classNames(
      'woman-story-card',
      `woman-story-card--${cardColor.label?.toLowerCase()}-theme`
    )}
  >
    <div className="woman-story-card__card-container">
      <div className="woman-story-card__woman-photo">
        <Image imageData={image} alt={womanName} />
      </div>
      <span className="woman-story-card__title">{title}</span>
      <span className="woman-story-card__woman-name">{womanName}</span>
    </div>
    <SvgImageIcon icon={icon} iconAlt={title} customClassName="woman-story-card__icon" />
    <DangerouslySetInnerHtml className="woman-story-card__woman-story" html={story} />
  </div>
);

export default WomanStoryCard;
